import { PRIO } from '../../../constants';
import {
  CreateProjectRequest,
  CreateSubprojectRequest,
  Project,
  ProjectStatus,
} from '../../../models/Project';
import {
  CreateHourlyRateRequest,
  HourlyRate,
} from '../../../models/HourlyRate';
import { KilometerRate } from '../../../models/KilometerRate';
import { apiUrl } from '../../../api';
import {
  CreateInternalProjectContactRequest,
  CreateExternalProjectContactRequest,
  ExternalProjectContact,
  InternalProjectContact,
  UpdateExternalProjectContactRequest,
  UpdateProjectContact,
} from '../../../models/ProjectContacts';
import {
  ProjectId,
  ProjectTabViews,
  ExternalProjectContactId,
  PlannerId,
} from '../../../models/Types';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import { ProjectDistributionListDrawerState } from '../reducers/projectDistributionList';
import { Contact } from '../../../models/Contact';
import { DispatchAction } from '../../../models/Redux';
import { ProjectExtensionAccessStateUpdatedEvent } from '../ws';
import { getAccessToken } from '../../../store/authEffect';

/** Project fetching */
export const FETCH_PROJECTS_REQUEST = PRIO + 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_COMMIT = PRIO + 'FETCH_PROJECTS_COMMIT';
export const FETCH_PROJECTS_ROLLBACK = PRIO + 'FETCH_PROJECTS_ROLLBACK';

export const fetchProjects = (includeArchived: boolean) => ({
  type: FETCH_PROJECTS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/Project/me${
          includeArchived ? `?includeArchived=${includeArchived}` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_PROJECTS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECTS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

/** Project fetching by Id */
export const FETCH_PROJECT_BY_ID_REQUEST = PRIO + 'FETCH_PROJECT_BY_ID_REQUEST';
export const FETCH_PROJECT_BY_ID_COMMIT = PRIO + 'FETCH_PROJECT_BY_ID_COMMIT';
export const FETCH_PROJECT_BY_ID_ROLLBACK =
  PRIO + 'FETCH_PROJECT_BY_ID_ROLLBACK';

export const fetchProjectById: (
  projectId: ProjectId,
  isProjectMember: boolean
) => DispatchAction<{ projectId: ProjectId; isProjectMember: boolean }> = (
  projectId: ProjectId,
  isProjectMember: boolean
) => ({
  type: FETCH_PROJECT_BY_ID_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/project/Project/${projectId}`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_PROJECT_BY_ID_COMMIT,
        meta: { projectId, isProjectMember },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_BY_ID_ROLLBACK,
        meta: { projectId, isProjectMember },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
    projectId,
    isProjectMember,
  },
});

/** Project creation */
export const CREATE_PROJECT_REQUEST = PRIO + 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_COMMIT = PRIO + 'CREATE_PROJECT_COMMIT';
export const CREATE_PROJECT_ROLLBACK = PRIO + 'CREATE_PROJECT_ROLLBACK';

export const createProject = (project: CreateProjectRequest, temporaryId) => ({
  type: CREATE_PROJECT_REQUEST,
  requiresAuth: true,
  payload: { project },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/project`,
        method: 'POST',
        json: project,
      },
      // action to dispatch when effect succeeds:
      commit: { type: CREATE_PROJECT_COMMIT, meta: { temporaryId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_PROJECT_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const CREATE_SUBPROJECT_REQUEST = PRIO + 'CREATE_SUBPROJECT_REQUEST';
export const CREATE_SUBPROJECT_COMMIT = PRIO + 'CREATE_SUBPROJECT_COMMIT';
export const CREATE_SUBPROJECT_ROLLBACK = PRIO + 'CREATE_SUBPROJECT_ROLLBACK';

export const createSubproject = (
  subproject: CreateSubprojectRequest,
  parentProject: Project,
  temporaryId: string
) => ({
  type: CREATE_SUBPROJECT_REQUEST,
  requiresAuth: true,
  payload: { subproject },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/project/${parentProject.projectId}/createSubproject`,
        method: 'POST',
        json: subproject,
      },
      // action to dispatch when effect succeeds:
      commit: { type: CREATE_SUBPROJECT_COMMIT, meta: { temporaryId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_SUBPROJECT_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createError',
          timeout: 6,
        },
      },
    },
    temporaryId,
    parentProject,
  },
});

export const UPDATE_PROJECT_REQUEST = PRIO + 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_COMMIT = PRIO + 'UPDATE_PROJECT_COMMIT';
export const UPDATE_PROJECT_ROLLBACK = PRIO + 'UPDATE_PROJECT_ROLLBACK';

export const updateProject = (project: Project, rollbackState: Project) => ({
  type: UPDATE_PROJECT_REQUEST,
  requiresAuth: true,
  payload: { project },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/Project/${project.projectId}`,
        method: 'PUT',
        json: project,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_PROJECT_COMMIT,
        meta: { projectId: project.projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_PROJECT_ROLLBACK,
        meta: { projectId: project.projectId, rollbackState },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
  },
});

/** Internal project contacts fetching */
export const FETCH_INTERNAL_PROJECT_CONTACTS_REQUEST =
  PRIO + 'FETCH_INTERNAL_PROJECT_CONTACTS_REQUEST';
export const FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT =
  PRIO + 'FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT';
export const FETCH_INTERNAL_PROJECT_CONTACTS_ROLLBACK =
  PRIO + 'FETCH_INTERNAL_PROJECT_CONTACTS_ROLLBACK';

export const fetchInternalProjectContacts = (projectId: ProjectId) => ({
  type: FETCH_INTERNAL_PROJECT_CONTACTS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/InternalProjectContact`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_INTERNAL_PROJECT_CONTACTS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchErrorInternalContacts',
          timeout: 6,
        },
      },
    },
  },
});

/** Internal project contact creation */

export const CREATE_INTERNAL_PROJECT_CONTACT_REQUEST =
  PRIO + 'CREATE_INTERNAL_PROJECT_CONTACT_REQUEST';
export const CREATE_INTERNAL_PROJECT_CONTACT_COMMIT =
  PRIO + 'CREATE_INTERNAL_PROJECT_CONTACT_COMMIT';
export const CREATE_INTERNAL_PROJECT_CONTACT_ROLLBACK =
  PRIO + 'CREATE_INTERNAL_PROJECT_CONTACT_ROLLBACK';

export const createInternalProjectContact = (
  createRequest: CreateInternalProjectContactRequest
) => ({
  type: CREATE_INTERNAL_PROJECT_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { ...createRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${createRequest.projectId}/InternalProjectContact`,
        method: 'POST',
        json: [createRequest],
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_INTERNAL_PROJECT_CONTACT_COMMIT,
        meta: {
          projectId: createRequest.projectId,
          contactId: createRequest.contactId,
        },
        snackbarErrorMessage: {
          label: 'projects:successMessages.addInternalProjectContacts',
          timeout: 6,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_INTERNAL_PROJECT_CONTACT_ROLLBACK,
        meta: {
          projectId: createRequest.projectId,
          contactId: createRequest.contactId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createInternalContactError',
          timeout: 6,
        },
      },
    },
    projectId: createRequest.projectId,
  },
});

export const UPDATE_PROJECT_CONTACTS_REQUEST =
  PRIO + 'UPDATE_PROJECT_CONTACTS_REQUEST';
export const UPDATE_PROJECT_CONTACTS_COMMIT =
  PRIO + 'UPDATE_PROJECT_CONTACTS_COMMIT';
export const UPDATE_PROJECT_CONTACTS_ROLLBACK =
  PRIO + 'UPDATE_PROJECT_CONTACTS_ROLLBACK';

export const updateProjectContacts = (
  projectId: ProjectId,
  request: UpdateProjectContact[],
  rollback: Array<InternalProjectContact | ExternalProjectContact>
) => {
  return async (dispatch): Promise<Response> => {
    try {
      const response = await fetch(
        `${apiUrl}/project/${projectId}/projectContact/bulkUpdate`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getAccessToken()}`,
          },
          body: JSON.stringify(request),
        }
      );

      const data = await response.json();

      if (response.ok) {
        dispatch({
          type: UPDATE_PROJECT_CONTACTS_COMMIT,
          meta: { projectId, request },
          payload: { response: data },
        });
      } else {
        dispatch({
          type: UPDATE_PROJECT_CONTACTS_ROLLBACK,
          meta: { projectId, request, rollback },
          error: 'Failed to update project contacts',
        });
      }

      return response;
    } catch (error) {
      const errorResponse = {
        ok: false,
        status: 500,
        statusText: error.message,
      } as Response;
      dispatch({
        type: UPDATE_PROJECT_CONTACTS_ROLLBACK,
        meta: { projectId, request, rollback },
        error: error.message,
      });
      return errorResponse;
    }
  };
};

/** Internal project contact update */

export const UPDATE_INTERNAL_PROJECT_CONTACT_REQUEST =
  PRIO + 'UPDATE_INTERNAL_PROJECT_CONTACT_REQUEST';
export const UPDATE_INTERNAL_PROJECT_CONTACT_COMMIT =
  PRIO + 'UPDATE_INTERNAL_PROJECT_CONTACT_COMMIT';
export const UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK =
  PRIO + 'UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK';

export const updateInternalProjectContact = (
  projectId: ProjectId,
  projectContacts: InternalProjectContact[],
  rollbackContacts: InternalProjectContact[]
) => {
  return async (dispatch): Promise<Response> => {
    try {
      const response = await fetch(
        `${apiUrl}/project/${projectId}/InternalProjectContact/bulkUpdate`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getAccessToken()}`,
          },
          body: JSON.stringify(projectContacts),
        }
      );

      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: UPDATE_INTERNAL_PROJECT_CONTACT_COMMIT,
          meta: { projectId: projectId, projectContacts },
          payload: data,
        });
      } else {
        dispatch({
          type: UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK,
          meta: { projectId: projectId, rollbackContacts },
          error: 'Failed to update internal project contacts',
        });
      }

      return response;
    } catch (error) {
      const errorResponse = {
        ok: false,
        status: 500,
        statusText: error.message,
      } as Response;
      dispatch({
        type: UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK,
        meta: { projectId: projectId, rollbackContacts },
        error: error.message,
      });
      return errorResponse;
    }
  };
};

/** External project contacts fetching */
export const FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST =
  PRIO + 'FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST';
export const FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT =
  PRIO + 'FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT';
export const FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK =
  PRIO + 'FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK';

export const fetchExternalProjectContacts = (projectId: ProjectId) => ({
  type: FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/ExternalProjectContact`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchErrorExternalContacts',
          timeout: 6,
        },
      },
    },
  },
});

/** External project contact creation */

export const CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST =
  PRIO + 'CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST';
export const CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT =
  PRIO + 'CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT';
export const CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK =
  PRIO + 'CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK';

export const createExternalProjectContact = (
  createRequest: CreateExternalProjectContactRequest,
  contact: Contact
) => ({
  type: CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { ...createRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${createRequest.projectId}/ExternalProjectContact`,
        method: 'POST',
        json: createRequest,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
        meta: {
          projectId: createRequest.projectId,
          contactId: createRequest.contactId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
        meta: {
          projectId: createRequest.projectId,
          contactId: createRequest.contactId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createExternalContactError',
          timeout: 6,
        },
      },
    },
    projectId: createRequest.projectId,
    contact: contact,
  },
});

/** Hourly rate fetching */
export const FETCH_HOURLY_RATES_REQUEST = PRIO + 'FETCH_HOURLY_RATES_REQUEST';
export const FETCH_HOURLY_RATES_COMMIT = PRIO + 'FETCH_HOURLY_RATES_COMMIT';
export const FETCH_HOURLY_RATES_ROLLBACK = PRIO + 'FETCH_HOURLY_RATES_ROLLBACK';

export const fetchHourlyRates = (projectId: ProjectId) => ({
  type: FETCH_HOURLY_RATES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/HourlyRate/currentHourlyRate`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_HOURLY_RATES_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_HOURLY_RATES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchErrorHourlyRates',
          timeout: 6,
        },
      },
    },
  },
});

/** Hourly rate creation */

export const CREATE_HOURLY_RATE_REQUEST = PRIO + 'CREATE_HOURLY_RATE_REQUEST';
export const CREATE_HOURLY_RATE_COMMIT = PRIO + 'CREATE_HOURLY_RATE_COMMIT';
export const CREATE_HOURLY_RATE_ROLLBACK = PRIO + 'CREATE_HOURLY_RATE_ROLLBACK';

export const createHourlyRate = (
  createRequest: CreateHourlyRateRequest,
  temporaryId: string
) => ({
  type: CREATE_HOURLY_RATE_REQUEST,
  requiresAuth: true,
  payload: { ...createRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${createRequest.projectId}/HourlyRate`,
        method: 'POST',
        json: [createRequest],
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_HOURLY_RATE_COMMIT,
        meta: {
          projectId: createRequest.projectId,
          temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_HOURLY_RATE_ROLLBACK,
        meta: {
          projectId: createRequest.projectId,
          temporaryId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createHourlyRateError',
          timeout: 6,
        },
      },
    },
    projectId: createRequest.projectId,
    temporaryId,
  },
});

/** Hourly rate update */

export const UPDATE_HOURLY_RATE_REQUEST = PRIO + 'UPDATE_HOURLY_RATE_REQUEST';
export const UPDATE_HOURLY_RATE_COMMIT = PRIO + 'UPDATE_HOURLY_RATE_COMMIT';
export const UPDATE_HOURLY_RATE_ROLLBACK = PRIO + 'UPDATE_HOURLY_RATE_ROLLBACK';

export const updateHourlyRates = (
  updatedHourlyRates: HourlyRate[],
  projectId: ProjectId,
  rollbackHourlyRates: HourlyRate[]
) => ({
  type: UPDATE_HOURLY_RATE_REQUEST,
  requiresAuth: true,
  payload: updatedHourlyRates,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/HourlyRate`,
        method: 'POST',
        json: updatedHourlyRates,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_HOURLY_RATE_COMMIT,
        meta: {
          projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_HOURLY_RATE_ROLLBACK,
        meta: {
          projectId,
        },
        rollbackHourlyRates,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createHourlyRateError',
          timeout: 6,
        },
      },
    },
    projectId,
  },
});

/** Kilometer rate fetching */
export const FETCH_KILOMETER_RATE_REQUEST =
  PRIO + 'FETCH_KILOMETER_RATE_REQUEST';
export const FETCH_KILOMETER_RATE_COMMIT = PRIO + 'FETCH_KILOMETER_RATE_COMMIT';
export const FETCH_KILOMETER_RATE_ROLLBACK =
  PRIO + 'FETCH_KILOMETER_RATE_ROLLBACK';

export const fetchKilometerRate = (projectId: ProjectId) => ({
  type: FETCH_KILOMETER_RATE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/KilometerRate/currentKilometerRate`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_KILOMETER_RATE_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_KILOMETER_RATE_ROLLBACK,
      },
    },
  },
});

/** Kilometer rate creation */

export const UPDATE_KILOMETER_RATE_REQUEST =
  PRIO + 'UPDATE_KILOMETER_RATE_REQUEST';
export const UPDATE_KILOMETER_RATE_COMMIT =
  PRIO + 'UPDATE_KILOMETER_RATE_COMMIT';
export const UPDATE_KILOMETER_RATE_ROLLBACK =
  PRIO + 'UPDATE_KILOMETER_RATE_ROLLBACK';

export const updateKilometerRate = (
  kilometerRate: KilometerRate,
  rollbackKilometerRate: KilometerRate
) => ({
  type: UPDATE_KILOMETER_RATE_REQUEST,
  requiresAuth: true,
  payload: { ...kilometerRate },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${kilometerRate.projectId}/KilometerRate`,
        method: 'POST',
        json: kilometerRate,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_KILOMETER_RATE_COMMIT,
        meta: {
          projectId: kilometerRate.projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_KILOMETER_RATE_ROLLBACK,
        meta: {
          projectId: kilometerRate.projectId,
        },
        rollbackKilometerRate,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.updateKilometerRateError',
          timeout: 6,
        },
      },
    },
    projectId: kilometerRate.projectId,
  },
});

/** External project contact update */

export const UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST =
  PRIO + 'UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST';
export const UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT =
  PRIO + 'UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT';
export const UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK =
  PRIO + 'UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK';

export const updateExternalProjectContact = (
  externalProjectContactId: ExternalProjectContactId,
  projectId: ProjectId,
  projectContact: UpdateExternalProjectContactRequest,
  rollbackContact: ExternalProjectContact
) => ({
  type: UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { ...projectContact },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/ExternalProjectContact/${externalProjectContactId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: projectContact,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
        meta: {
          projectId,
          externalProjectContactId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
        meta: {
          projectId,
          externalProjectContactId,
        },
        rollbackContact,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.updateExternalContactError',
          timeout: 6,
        },
      },
    },
    projectId,
    externalProjectContactId,
  },
});

/** current project */
export interface ActiveProjectAction {
  type: string;
  projectId: ProjectId;
}
export const SET_ACTIVE_PROJECT = PRIO + 'SET_ACTIVE_PROJECT';
export const setActiveProject: (projectId: ProjectId) => ActiveProjectAction = (
  projectId: ProjectId
) => ({
  type: SET_ACTIVE_PROJECT,
  projectId,
});

/** Favorites */
export const ADD_TO_PROJECT_FAVORITES_REQUEST =
  PRIO + 'ADD_TO_PROJECT_FAVORITES_REQUEST';
export const ADD_TO_PROJECT_FAVORITES_COMMIT =
  PRIO + 'ADD_TO_PROJECT_FAVORITES_COMMIT';
export const ADD_TO_PROJECT_FAVORITES_ROLLBACK =
  PRIO + 'ADD_TO_PROJECT_FAVORITES_ROLLBACK';

export const addToProjectFavorites = (projectId: ProjectId) => ({
  type: ADD_TO_PROJECT_FAVORITES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/Project/${projectId}/favorite`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ADD_TO_PROJECT_FAVORITES_COMMIT,
        meta: {
          projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ADD_TO_PROJECT_FAVORITES_ROLLBACK,
        meta: {
          projectId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.addToFavoritesError',
          timeout: 6,
        },
      },
    },
    projectId,
  },
});

export const REMOVE_FROM_PROJECT_FAVORITES_REQUEST =
  PRIO + 'REMOVE_FROM_PROJECT_FAVORITES_REQUEST';
export const REMOVE_FROM_PROJECT_FAVORITES_COMMIT =
  PRIO + 'REMOVE_FROM_PROJECT_FAVORITES_COMMIT';
export const REMOVE_FROM_PROJECT_FAVORITES_ROLLBACK =
  PRIO + 'REMOVE_FROM_PROJECT_FAVORITES_ROLLBACK';

export const removeFromProjectFavorites = (projectId: ProjectId) => ({
  type: REMOVE_FROM_PROJECT_FAVORITES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/Project/${projectId}/unfavorite`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: REMOVE_FROM_PROJECT_FAVORITES_COMMIT,
        meta: {
          projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: REMOVE_FROM_PROJECT_FAVORITES_ROLLBACK,
        meta: {
          projectId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.removeFromFavoritesError',
          timeout: 6,
        },
      },
    },
    projectId,
  },
});

/** External project contact archiving */

export const ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST =
  PRIO + 'ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST';
export const ARCHIVE_EXTERNAL_PROJECT_CONTACT_COMMIT =
  PRIO + 'ARCHIVE_EXTERNAL_PROJECT_CONTACT_COMMIT';
export const ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK =
  PRIO + 'ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK';

export const archiveExternalProjectContact = (
  externalProjectContactId: ExternalProjectContactId,
  projectId: ProjectId,
  rollbackContact: ExternalProjectContact
) => ({
  type: ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/ExternalProjectContact/${externalProjectContactId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_EXTERNAL_PROJECT_CONTACT_COMMIT,
        meta: {
          projectId,
          externalProjectContactId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
        meta: {
          projectId,
          externalProjectContactId,
        },
        rollbackContact,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.archiveExternalContactError',
          timeout: 6,
        },
      },
    },
    projectId,
    externalProjectContactId,
  },
});

/* Set tab view of project module */
export interface ActiveProjectTabViewAction {
  type: string;
  activeTab: ProjectTabViews;
}

export const SET_ACTIVE_PROJECT_TAB_VIEW = PRIO + 'SET_ACTIVE_PROJECT_TAB_VIEW';

export const setActiveProjectTabView: (
  projectTabView: ProjectTabViews
) => ActiveProjectTabViewAction = (activeTab: ProjectTabViews) => ({
  type: SET_ACTIVE_PROJECT_TAB_VIEW,
  activeTab,
});

/** Sync Action for Saga */
export const SYNC_GLOBAL_PROJECTS = PRIO + 'SYNC_GLOBAL_PROJECTS';

export const syncGlobalProjects = () => ({ type: SYNC_GLOBAL_PROJECTS });

/** Project overview fetching */
export const FETCH_PROJECTS_OVERVIEW_REQUEST =
  PRIO + 'FETCH_PROJECTS_OVERVIEW_REQUEST';
export const FETCH_PROJECTS_OVERVIEW_COMMIT =
  PRIO + 'FETCH_PROJECTS_OVERVIEW_COMMIT';
export const FETCH_PROJECTS_OVERVIEW_ROLLBACK =
  PRIO + 'FETCH_PROJECTS_OVERVIEW_ROLLBACK';

export const fetchProjectsOverview = () => ({
  type: FETCH_PROJECTS_OVERVIEW_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/project/Project`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_PROJECTS_OVERVIEW_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECTS_OVERVIEW_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

/* Set last project location */
export interface LastProjectLocation {
  type: string;
  projectLocation: string;
}

export const SET_LAST_PROJECT_LOCATION = PRIO + 'SET_LAST_PROJECT_LOCATION';

export const setLastProjectLocation: (
  projectLocation: string
) => LastProjectLocation = (projectLocation: string) => ({
  type: SET_LAST_PROJECT_LOCATION,
  projectLocation,
});

/* Fetch Project Distribution List */
export const FETCH_PROJECT_DISTRIBUTION_LIST_REQUEST =
  PRIO + 'FETCH_PROJECT_DISTRIBUTION_LIST_REQUEST';
export const FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT =
  PRIO + 'FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT';
export const FETCH_PROJECT_DISTRIBUTION_LIST_ROLLBACK =
  PRIO + 'FETCH_PROJECT_DISTRIBUTION_LIST_ROLLBACK';

export const fetchProjectDistribustionList = (projectId: ProjectId) => ({
  type: FETCH_PROJECT_DISTRIBUTION_LIST_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/ProjectDistributionList`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchProjectDistributionListError',
          timeout: 6,
        },
      },
    },
  },
});

/** Project Distribution List creation */
export const CREATE_PROJECT_DISTRIBUTION_LIST_REQUEST =
  PRIO + 'CREATE_PROJECT_DISTRIBUTION_LIST_REQUEST';
export const CREATE_PROJECT_DISTRIBUTION_LIST_COMMIT =
  PRIO + 'CREATE_PROJECT_DISTRIBUTION_LIST_COMMIT';
export const CREATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK =
  PRIO + 'CREATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK';

export const createProjectDistributionList = (
  projectDistributionList: ProjectDistributionList,
  temporaryId
) => ({
  type: CREATE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  requiresAuth: true,
  payload: { ...projectDistributionList },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectDistributionList.projectId}/ProjectDistributionList`,
        method: 'POST',
        json: projectDistributionList,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_PROJECT_DISTRIBUTION_LIST_COMMIT,
        meta: { temporaryId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.createProjectDistributionListError',
          timeout: 6,
        },
      },
    },
    temporaryId,
    projectId: projectDistributionList.projectId,
  },
});

export const UPDATE_PROJECT_DISTRIBUTION_LIST_REQUEST =
  PRIO + 'UPDATE_PROJECT_DISTRIBUTION_LIST_REQUEST';
export const UPDATE_PROJECT_DISTRIBUTION_LIST_COMMIT =
  PRIO + 'UPDATE_PROJECT_DISTRIBUTION_LIST_COMMIT';
export const UPDATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK =
  PRIO + 'UPDATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK';

export const updateProjectDistributionList = (
  projectDistributionList: ProjectDistributionList,
  rollbackState: ProjectDistributionList
) => ({
  type: UPDATE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  requiresAuth: true,
  payload: { ...projectDistributionList },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectDistributionList.projectId}/ProjectDistributionList/${projectDistributionList.projectDistributionListId}`,
        method: 'PUT',
        json: projectDistributionList,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_PROJECT_DISTRIBUTION_LIST_COMMIT,
        meta: {
          projectId: projectDistributionList.projectId,
          projectDistributionListId:
            projectDistributionList.projectDistributionListId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
        meta: {
          projectId: projectDistributionList.projectId,
          projectDistributionListId:
            projectDistributionList.projectDistributionListId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.updateProjectDistributionListError',
          timeout: 6,
        },
        rollbackState,
      },
    },
    projectId: projectDistributionList.projectId,
    projectDistributionListId:
      projectDistributionList.projectDistributionListId,
  },
});

export const ARCHIVE_PROJECT_DISTRIBUTION_LIST_REQUEST =
  PRIO + 'ARCHIVE_PROJECT_DISTRIBUTION_LIST_REQUEST';
export const ARCHIVE_PROJECT_DISTRIBUTION_LIST_COMMIT =
  PRIO + 'ARCHIVE_PROJECT_DISTRIBUTION_LIST_COMMIT';
export const ARCHIVE_PROJECT_DISTRIBUTION_LIST_ROLLBACK =
  PRIO + 'ARCHIVE_PROJECT_DISTRIBUTION_LIST_ROLLBACK';

export const archiveProjectDistributionList = (
  projectDistributionList: ProjectDistributionList
) => ({
  type: ARCHIVE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  requiresAuth: true,
  payload: { ...projectDistributionList },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectDistributionList.projectId}/ProjectDistributionList/${projectDistributionList.projectDistributionListId}/archive`,
        method: 'PUT',
        json: projectDistributionList,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_PROJECT_DISTRIBUTION_LIST_COMMIT,
        meta: {
          projectId: projectDistributionList.projectId,
          projectDistributionListId:
            projectDistributionList.projectDistributionListId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
        meta: {
          projectId: projectDistributionList.projectId,
          projectDistributionListId:
            projectDistributionList.projectDistributionListId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.archiveProjectDistributionListError',
          timeout: 6,
        },
        rollBackState: projectDistributionList,
      },
    },
    projectId: projectDistributionList.projectId,
    projectDistributionListId:
      projectDistributionList.projectDistributionListId,
  },
});

/* Set project distribution list drawer */
export interface ProjectDistributionListDrawerStateAction {
  type: string;
  drawerState: ProjectDistributionListDrawerState;
}

export const SET_PROJECT_DISTRIBUTION_LIST_DRAWER_STATE =
  PRIO + 'SET_PROJECT_DISTRIBUTION_LIST_DRAWER_STATE';

export const setProjectDistributionDrawerState: (
  drawerState: ProjectDistributionListDrawerState
) => ProjectDistributionListDrawerStateAction = (drawerState) => ({
  type: SET_PROJECT_DISTRIBUTION_LIST_DRAWER_STATE,
  drawerState,
});

export const UPDATE_PROJECT_STATUS = PRIO + 'UPDATE_PROJECT_STATUS';

export const updateProjectStatus: (
  projectId: ProjectId,
  projectStatus: ProjectStatus
) => any = (projectId, projectStatus) => ({
  type: UPDATE_PROJECT_STATUS,
  meta: { projectId },
  payload: { projectStatus },
});

export const SET_PROJECT_MASTERPLAN = PRIO + 'SET_PROJECT_MASTERPLAN';

export const setProjectMasterplan: (
  projectId: ProjectId,
  masterPlanId: PlannerId
) => any = (projectId, masterPlanId) => ({
  type: SET_PROJECT_MASTERPLAN,
  meta: { projectId },
  payload: { masterPlanId },
});

export const UPDATE_PROJECT_MASTERPLAN_REQUEST =
  PRIO + 'UPDATE_PROJECT_MASTERPLAN_REQUEST';
export const UPDATE_PROJECT_MASTERPLAN_COMMIT =
  PRIO + 'UPDATE_PROJECT_MASTERPLAN_COMMIT';
export const UPDATE_PROJECT_MASTERPLAN_ROLLBACK =
  PRIO + 'UPDATE_PROJECT_MASTERPLAN_ROLLBACK';

export const updateProjectMasterplan: (
  projectId: ProjectId,
  masterPlanId: PlannerId,
  rollbackMasterPlanId: PlannerId
) => any = (projectId, masterPlanId, rollbackMasterPlanId) => ({
  type: UPDATE_PROJECT_MASTERPLAN_REQUEST,
  requiresAuth: true,
  payload: { masterPlanId },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/Planner/masterPlan`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        json: { masterPlanId },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_PROJECT_MASTERPLAN_COMMIT,
        meta: { projectId, rollbackMasterPlanId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_PROJECT_MASTERPLAN_ROLLBACK,
        meta: { projectId, rollbackMasterPlanId },
        snackbarErrorMessage: {
          label: 'project:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    projectId,
    rollbackMasterPlanId,
  },
});

/** project news fetching */
export const FETCH_PROJECT_NEWS_REQUEST = PRIO + 'FETCH_PROJECT_NEWS_REQUEST';
export const FETCH_PROJECT_NEWS_COMMIT = PRIO + 'FETCH_PROJECT_NEWS_COMMIT';
export const FETCH_PROJECT_NEWS_ROLLBACK = PRIO + 'FETCH_PROJECT_NEWS_ROLLBACK';

export const fetchProjectNews = () => ({
  type: FETCH_PROJECT_NEWS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/ProjectNews`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_PROJECT_NEWS_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_NEWS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'projects:errorMessages.fetchProjectNews',
          timeout: 6,
        },
      },
    },
  },
});

/** Internal project contacts fetching */
export const DELETE_PROJECT_NEWS_REQUEST = PRIO + 'DELETE_PROJECT_NEWS_REQUEST';
export const DELETE_PROJECT_NEWS_COMMIT = PRIO + 'DELETE_PROJECT_NEWS_COMMIT';
export const DELETE_PROJECT_NEWS_ROLLBACK =
  PRIO + 'DELETE_PROJECT_NEWS_ROLLBACK';

export const deleteProjectNews = (projectId: ProjectId) => ({
  type: DELETE_PROJECT_NEWS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/${projectId}/ProjectNews`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_PROJECT_NEWS_COMMIT,
        meta: {
          projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_PROJECT_NEWS_ROLLBACK,
      },
    },
    projectId,
  },
});

export const UPDATE_INTERNAL_PROJECT_CONTACT_ME_NOTIFICATION =
  PRIO + 'UPDATE_INTERNAL_PROJECT_CONTACT_ME_NOTIFICATION';

export const updateInternalProjectContactMeNotification = (
  projectId: ProjectId,
  payload: InternalProjectContact
) => ({
  type: UPDATE_INTERNAL_PROJECT_CONTACT_ME_NOTIFICATION,
  projectId,
  payload,
});

export const WS_UPDATE_PROJECT_EXTENSION_ME =
  PRIO + 'WS_UPDATE_PROJECT_EXTENSION_ME';

export const wsUpdateProjectExtensionMe = (
  payload: ProjectExtensionAccessStateUpdatedEvent
) => ({
  type: WS_UPDATE_PROJECT_EXTENSION_ME,
  payload,
});

/** Leave Project */
export const LEAVE_PROJECT_ME_REQUEST = PRIO + 'LEAVE_PROJECT_ME_REQUEST';
export const LEAVE_PROJECT_ME_COMMIT = PRIO + 'LEAVE_PROJECT_ME_COMMIT';
export const LEAVE_PROJECT_ME_ROLLBACK = PRIO + 'LEAVE_PROJECT_ME_ROLLBACK';

export const leaveProjectMe = (projectId: ProjectId) => ({
  type: LEAVE_PROJECT_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/project/InternalProjectContact/${projectId}/archive/me`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: LEAVE_PROJECT_ME_COMMIT,
        meta: {
          projectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: LEAVE_PROJECT_ME_ROLLBACK,
        meta: {
          projectId,
        },
        snackbarErrorMessage: {
          label: 'projects:errorMessages.leaveProjectError',
          timeout: 6,
        },
      },
    },
    projectId,
  },
});
