import React, { useRef, useState } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import NavigationBar from '../../../components/NavigationBar';
import { ProjectId } from '../../../models/Types';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';

import ProjectContactsPage from './ProjectContactsPage';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { createExternalProjectContact } from '../../projects/actions';
import { apiFetchContact } from '../api';
import AddExternalContactDrawer from './AddExternalContactDrawer';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
} from '../../../models/ProjectContacts';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
  menu: {
    maxWidth: theme.old.components.menuMaxWidth,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  page: {
    height: '100%',
  },
  content: {
    overflow: 'hidden',
  },
}));

interface ProjectContactsSubModuleProps {
  projectId: ProjectId;
}

export const ProjectContactsSubModule: React.FC<
  ProjectContactsSubModuleProps
> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [externalUserDrawerVisible, setExternalUserDrawerVisible] =
    useState(false);
  const { projectId } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const updateTableRef = useRef<(() => void) | null>(null);

  const handleOnFinish = async (values) => {
    setLoading(true);
    const promises = values.contacts.map(async (contact) => {
      const { result, data } = await apiFetchContact(contact.contactId);
      if (result.status >= 200 && result.status < 300) {
        return dispatch(
          createExternalProjectContact(
            {
              contactId: contact.contactId,
              projectId: projectId,
              jobTitle: contact.jobTitle,
              notes: contact.notes,
            },
            data
          )
        );
      } else {
        return null;
      }
    });

    await Promise.all(promises);
    //// this is just a workaround until we implement the optimistic write story
    setTimeout(() => {
      if (updateTableRef.current) {
        updateTableRef.current();
      }
      setLoading(false);
      form.resetFields();
      setExternalUserDrawerVisible(false);
    }, 2000);
  };
  return (
    <Flex.Column className={classes.root}>
      <NavigationBar>
        <Button
          iconProp={['fal', 'user-plus']}
          onClick={() => setExternalUserDrawerVisible(true)}
        >
          {t(
            'contacts:projectContactsPage.navigatioBarButtons.addExtrnalContact'
          )}
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.body}>
        <Flex.Item flex={1} className={classes.content}>
          <Routes>
            <Route
              path="/"
              element={
                <FilterContextProvider<
                  ProjectMember,
                  ProjectMemberCalculatedData
                >
                  searchType="projectMembers"
                  equalityFunction={(a, b) =>
                    a.data?.contactId === b.data?.contactId
                  }
                  customDefaultSearchParameters={[
                    {
                      parameterName: 'Data.ProjectId',
                      defaultValue: projectId,
                      defaultMethod: 'eq',
                    },
                  ]}
                >
                  <ProjectContactsPage
                    projectId={projectId}
                    className={classes.page}
                  />
                </FilterContextProvider>
              }
            />
          </Routes>
        </Flex.Item>
      </Flex.Row>
      <AddExternalContactDrawer
        visible={externalUserDrawerVisible}
        onClose={() => {
          setExternalUserDrawerVisible(false);
          form.resetFields();
        }}
        onFinish={handleOnFinish}
        loading={loading}
        form={form}
        projectId={projectId}
      />
    </Flex.Column>
  );
};

export default ProjectContactsSubModule;
